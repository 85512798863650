<template>
    <div class="page economy-page">
        <div class="page__title">
            <nav-back/>
            Экономика - общие значения
        </div>

        <div class="form-group">
            <div class="form-group__left-block">
                <label class="economy-page__label">Награда за прохождение главы</label>
                <b-form-select v-model.number="selectedChapterRewardOption" :options="currenciesOptions"
                               class="form-control"/>
                <input type="number" placeholder="Количество" class="form-control mt-2"
                       v-model.number="chapterRewardValue"
                       min=0 oninput="validity.valid||(value=0)"
                >
            </div>
            <div class="form-group__right-block" @click="onSaveChapterRewardClick">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor"
                     class="bi bi-check-square" viewBox="0 0 16 16" v-b-tooltip.hover title="Применить">
                    <path
                        d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                    <path
                        d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                </svg>
            </div>
        </div>

        <div class="form-group mt-3">
            <div class="form-group__left-block">
                <label class="economy-page__label">Награда за прохождение истории</label>
                <b-form-select v-model.number="selectedStoryRewardOption" :options="currenciesOptions"
                               class="form-control"/>
                <input type="number" placeholder="Количество" class="form-control mt-2"
                       v-model.number="storyRewardValue"
                       min=0 oninput="validity.valid||(value=0)"
                >
            </div>
            <div class="form-group__right-block" @click="onSaveStoryRewardClick">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor"
                     class="bi bi-check-square" viewBox="0 0 16 16" v-b-tooltip.hover title="Применить">
                    <path
                        d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                    <path
                        d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                </svg>
            </div>
        </div>

        <div class="form-group mt-3">
            <div class="form-group__left-block">
                <label class="economy-page__label">Цена кастомизации</label>
                <b-form-select v-model.number="selectedCustomizationOption" :options="currenciesOptions"
                               class="form-control"/>
                <input type="number" placeholder="Количество" class="form-control mt-2"
                       v-model.number="customizationPriceValue"
                       min=0 oninput="validity.valid||(value=0)"
                >
            </div>
            <div class="form-group__right-block" @click="onSaveCustomizationPriceClick">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor"
                     class="bi bi-check-square" viewBox="0 0 16 16" v-b-tooltip.hover title="Применить">
                    <path
                        d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                    <path
                        d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                </svg>
            </div>
        </div>

        <div class="form-group mt-3">
            <div class="form-group__left-block">
                <label class="economy-page__label">Цена открытия истории</label>
                <b-form-select v-model.number="selectedUnlockStoryOption" :options="currenciesOptions"
                               class="form-control"/>
                <input type="number" placeholder="Количество" class="form-control mt-2"
                       v-model.number="unlockStoryPriceValue"
                       min=0 oninput="validity.valid||(value=0)"
                >
            </div>
            <div class="form-group__right-block" @click="onSaveUnlockStoryPriceClick">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor"
                     class="bi bi-check-square" viewBox="0 0 16 16" v-b-tooltip.hover title="Применить">
                    <path
                        d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                    <path
                        d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import bus from '@/plugins/bus';

export default {
    data: () => ({
        currenciesOptions: [{value: 0, text: 'Ожидание'}],
        selectedChapterRewardOption: 0,
        selectedStoryRewardOption: 0,
        selectedCustomizationOption: 0,
        selectedUnlockStoryOption: 0,
        chapterRewardValue: 0,
        storyRewardValue: 0,
        customizationPriceValue: 0,
        unlockStoryPriceValue: 0,
    }),
    components: {
        NavBack: () => import('../../components/navigation/NavBack')
    },
    computed: {
        ...mapGetters('firebase', ['currencies', 'economyConfig'])
    },
    async created() {
        await this.getConfig();
        this.fillSelects();
    },
    methods: {
        ...mapActions('firebase', ['getConfig', 'updateEconomyConfig']),
        fillSelects() {
            this.currenciesOptions = this.currencies
                .filter(currency => currency.id > 0)
                .map(currency => {
                    return {value: currency.id, text: currency.name};
                });

            this.selectedChapterRewardOption = this.economyConfig.chapter_reward_currency_id || 1;
            this.chapterRewardValue = this.economyConfig.chapter_reward_currency_value || 0;

            this.selectedStoryRewardOption = this.economyConfig.story_reward_currency_id || 1;
            this.storyRewardValue = this.economyConfig.story_reward_currency_value || 0;

            this.selectedCustomizationOption = this.economyConfig.customization_currency_id || 1;
            this.customizationPriceValue = this.economyConfig.customization_currency_value || 0;

            this.selectedUnlockStoryOption = this.economyConfig.story_unlock_currency_id || 1;
            this.unlockStoryPriceValue = this.economyConfig.story_unlock_currency_value || 0;
        },
        checkResponse(res) {
            if (!res.error) bus.$emit('show-notification-message', 'Сохранено');
            else bus.$emit('show-notification-message', `Ошибка: ${res.error.toString()}`);
        },
        async onSaveChapterRewardClick() {
            const data = {
                chapter_reward_currency_id: this.selectedChapterRewardOption,
                chapter_reward_currency_value: this.chapterRewardValue
            };
            const response = await this.updateEconomyConfig(data);
            this.checkResponse(response);
        },
        async onSaveStoryRewardClick() {
            const data = {
                story_reward_currency_id: this.selectedStoryRewardOption,
                story_reward_currency_value: this.storyRewardValue
            };
            const response = await this.updateEconomyConfig(data);
            this.checkResponse(response);
        },
        async onSaveCustomizationPriceClick() {
            const data = {
                customization_currency_id: this.selectedCustomizationOption,
                customization_currency_value: this.customizationPriceValue
            };
            const response = await this.updateEconomyConfig(data);
            this.checkResponse(response);
        },
        async onSaveUnlockStoryPriceClick() {
            const data = {
                story_unlock_currency_id: this.selectedUnlockStoryOption,
                story_unlock_currency_value: this.unlockStoryPriceValue
            };
            const response = await this.updateEconomyConfig(data);
            this.checkResponse(response);
        }
    }
};
</script>

<style lang="scss">
.economy-page {
    &__label {
        float: left;
    }

    .form-group {
        display: flex;
        flex-direction: row;
        align-items: center;

        &__left-block {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 25%;
        }

        &__right-block {
            cursor: pointer;
            margin: 32px 0 0 16px;
        }
    }

    .form-control {
        display: flex;
    }
}
</style>
